import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from 'styled-components';

import Layout from "../layout/Layout"
import SEO from "../layout/SEO"

// Import global styles
import GlobalStyle from '../styles/global';

// Component styles
const StyledBlog = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-row-gap: 2rem;
  padding: 10rem var(--padding-right) 10rem var(--padding-left);
  max-width: var(--max-width);
  margin: 0 auto;

  .blog__image {
    width: 100%;
  }

  .goBackLink {
    color: var(--color-primary);
    text-decoration: none;
    outline: 0;
    border-bottom: 1px solid transparent;

    &:hover {
      color: var(--color-primary);
      text-decoration: none;
      outline: 0;
      border-bottom: 1px solid var(--color-primary);
    }
  }

  p {
    line-height: 1.5;
  }
`;

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;
  const blogImage = post.frontmatter.image.publicURL;

  console.log('blog post template. data: ', data);
  console.log('blog post template. post: ', post);

  return (
    <React.Fragment>
      <GlobalStyle />
      <Layout location={location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        
        <StyledBlog>
          <a href="/nieuwsartikelen" class="goBackLink">Ga terug naar alle nieuwsartikelen</a>
          
          {blogImage !== '' && typeof(blogImage) != 'undefined' && (
            <img src={blogImage !== '' && typeof(blogImage) != 'undefined' ? blogImage : "https://via.placeholder.com/200x130&text=up2date.nl"} alt="Nieuwsartikel foto" className="blog__image" />
          )}
          
          <h1 class="blog__title">{post.frontmatter.title}</h1>
          <p className="blog__date">
            {post.frontmatter.date}
          </p>
          <MDXRenderer>{post.body}</MDXRenderer>
        </StyledBlog>
        
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/nieuwsartikelen${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/nieuwsartikelen${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    </React.Fragment>
  )
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        image {
            publicURL
        }
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`